<template>
    <div class="p-fluid">
        <div class="formgrid grid">
            <div class="field col-12 md:col-6 lg:col-3">
                <label for="tanggal">Tanggal</label>
                <Calendar selectionMode="single"  :manualInput="false" v-model="period" dateFormat="dd/mm/yy" icon="pi pi-calendar" :showIcon="true"/>
            </div>
            <div class="field col-12 md:col-6 lg:col-3">
                <label>User</label>
                <Dropdown id="userid" dataKey="userid" v-model="filters.userid" :loading="loadingDropdownUser"
                    :options="dataDropdownUser"
                    optionLabel="label" optionValue="userid" placeholder="Pilih User"
                    :filter="true" :showClear="true" @filter="searchDropdownUser($event, 'filter')" 
                    />
            </div>
        </div>
    </div>
    <div class="flex flex-column md:flex-row md:justify-content-between md:align-items-center mb-2">
        <div>
            <Button type="button" :label="'Total '+this.total_marker" icon="pi pi-map" class="p-button-primary"/>
        </div>
        <span class="block mt-2 md:mt-0 p-input-icon-left">
            <Button icon="pi pi-refresh" :loading="loading" class="p-button-warning" @click="showMarker()"/>
        </span>
    </div>
    <BlockUI :blocked="loading">
        <div class="map" id="map-tracking-route"></div>
    </BlockUI>
</template>

<script>

import moment from 'moment';

// eslint-disable-next-line no-undef
var markers=  L.markerClusterGroup({
        disableClusteringAtZoom: 5,
        maxClusterRadius: 100,
    });

export default {
    props: [
		// "filter",
		// "filter2",
    ],
    data() {
        return {
            loadingDropdownUser: false,

            // dataDropdown
            dataDropdownUser: null,

            // filter
            filters: {
                userid: null,
            },

            period: new Date(),

            // maps
            loading: false,
            map: null,
            zoom: 5,
            lat: -3.375126556326247,
            lng: 118.84902874210889,
            location_list: [],
            total_marker: 0,
            
        }
    },
    computed:{
        period_start(){ 
            return moment(this.period, 'YYYY-MM-DD').format('YYYY-MM-DD'); 
        },
    },
    mounted() {

        // eslint-disable-next-line no-undef
        this.map = L.map('map-tracking-route', {
            attributionControl: false,
            doubleClickZoom: true,
            zoomControl: true,
            zoom:1,
            zoomAnimation:false,
            fadeAnimation:true,
            markerZoomAnimation:true
        }).setView([this.lat, this.lng], this.zoom);

        // eslint-disable-next-line no-undef
        L.tileLayer('https://{s}.tile.osm.org/{z}/{x}/{y}.png', {
            attribution: '© OpenStreetMap'
        }).addTo(this.map);

    },
    created(){
        this.showMarker();

        this.searchDropdownUser('');
    },
    watch: {
        // filter: {
        //     handler() {
        //         setTimeout(() => {
        //             this.showMarker() // Panggil metode yang ingin dijalankan
        //         }, 500);
        //     },
        //     deep: true
        // },
        // filter2: {
        //     handler() {
        //         setTimeout(() => {
        //             this.showMarker() // Panggil metode yang ingin dijalankan
        //         }, 500);
        //     },
        //     deep: true
        // },
        filters: {
            handler() {
                setTimeout(() => {
                    this.showMarker()
                }, 500);
            },
            deep: true
        },
        period: {
            handler() {
                setTimeout(() => {
                    this.showMarker() // Panggil metode yang ingin dijalankan
                }, 500);
            },
        },
    },
    methods:{
        // DROPDOWN
        searchDropdownUser(event, purpose, valueEdit) {
            setTimeout(() => {

                if (valueEdit) {
                    this.$refs.duser.filterValue = valueEdit;
                }

                if (purpose == "filter") {
                    this.loadingDropdownUser = true;
                }

                this.axios({
                    method: 'GET',
                    url: process.env.VUE_APP_ROOT_API + 'web/select2/userapp-tracking',
                    params: {
                        "search": valueEdit ? valueEdit : event.value,
                    }
                })
                    .then(res => {

                        if (purpose == "filter") {
                            this.dataDropdownUser = res.data.data;
                            this.loadingDropdownUser = false;
                        } else if (purpose == null) {
                            this.dataDropdownUser = res.data.data;
                        }
                    })
                    .catch((err) => {
                        console.log(err);
                    });
            }, 250);
        },
        //MAPS
        showMarker() {
            this.loading = true;

            // Hapus semua marker dan polyline sebelumnya
            markers.clearLayers();
            if (this.currentPolyline) {
                this.map.removeLayer(this.currentPolyline);
                this.currentPolyline = null; // Reset polyline saat ini
            }

            this.axios({
                method: 'GET',
                url: process.env.VUE_APP_ROOT_API + 'web/dashboard/tracking-route',
                params: {
                    "userid": this.filters.userid,
                    "date": this.period_start,
                },
            })
            .then(res => {
                this.location_list = res.data.data;
                this.total_marker = res.data.data.length;

                // Urutkan lokasi berdasarkan sequence
                const sortedLocations = this.location_list
                    .filter(location => location.sampling_lat != null && location.sampling_lng != null)
                    .sort((a, b) => a.sequence - b.sequence);

                const polylineCoordinates = [];

                sortedLocations.forEach((value, index) => {
                    var htmlcontent = "<b>Project</b> : " + value.project_name + "</b><br/>";
                        htmlcontent += "<b>User</b> : " + value.user_label + "<br/>";
                        htmlcontent += "<b>Area</b> : " + value.area_label + "<br/>";
                        htmlcontent += "<b>Account Type</b> : " + value.cust_account_type + "<br/>";
                        htmlcontent += "<b>Venue</b> : " + value.cust_location_name + "<br/>";
                        htmlcontent += "<b>Checkin Time</b> : " + value.cust_in_time + "<br/>";
                        htmlcontent += "<b>Konsumen</b> : " + value.consumer_name + "<br/>";
                        htmlcontent += "<b>Sampling Start</b> : " + value.started_at + "<br/>";
                        htmlcontent += "<b>Sampling End</b> : " + value.end_at + "<br/>";
                        htmlcontent += "<b>Duration</b> : " + value.sampling_duration + "<br/>";
                        htmlcontent += "<b>No.</b> : " + value.sequence + "<br/>";

                    // Tentukan ikon untuk marker
                    let markerOptions = {
                        radius: 8,
                        fillOpacity: 0.8,
                        color: "blue", // Warna default
                    };

                    // Nomor awal (ikon berbeda)
                    if (index === 0) {
                        markerOptions.color = "green"; // Warna hijau untuk titik awal
                        markerOptions.radius = 20; // Ukuran lebih besar
                    }

                    // Nomor akhir (ikon berbeda)
                    if (index === sortedLocations.length - 1) {
                        markerOptions.color = "red"; // Warna merah untuk titik akhir
                        markerOptions.radius = 20; // Ukuran lebih besar
                    }

                    // Tambahkan marker
                    // eslint-disable-next-line no-undef
                    var marker = L.circleMarker([value.sampling_lat, value.sampling_lng], markerOptions);

                    marker.bindPopup(htmlcontent);
                    markers.addLayer(marker);

                    // Tambahkan koordinat ke array polyline
                    polylineCoordinates.push([value.sampling_lat, value.sampling_lng]);
                });

                // Tambahkan marker ke peta
                this.map.addLayer(markers);

                // Gambar garis polyline berdasarkan urutan koordinat
                if (polylineCoordinates.length > 1) {
                    // eslint-disable-next-line no-undef
                    this.currentPolyline = L.polyline(polylineCoordinates, { color: 'blue' }).addTo(this.map);
                    this.map.fitBounds(this.currentPolyline.getBounds()); // Sesuaikan tampilan peta
                }

                this.loading = false;
            })
            .catch(err => {
                this.loading = false;
                console.log(err);
            });
        }

    }
}
</script>

<style>
	.map {
		height: 710px;
        width: 100%;
	}
    /*Legend specific*/
    .legend {
        padding: 6px 8px;
        font: 14px Arial, Helvetica, sans-serif;
        background: white;
        background: rgba(255, 255, 255, 0.8);
        /*box-shadow: 0 0 15px rgba(0, 0, 0, 0.2);*/
        /*border-radius: 5px;*/
        line-height: 24px;
        color: #555;
    }

    .legend h4 {
        text-align: center;
        font-size: 16px;
        margin: 2px 12px 8px;
        color: #777;
    }

    .legend span {
        position: relative;
        bottom: 3px;
    }

    .legend i {
        width: 18px;
        height: 18px;
        float: left;
        margin: 0 8px 0 0;
        opacity: 0.7;
    }

    .legend i.icon {
        background-size: 18px;
        background-color: rgba(255, 255, 255, 1);
    }
</style>
